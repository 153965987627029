/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.close-of-play-text {
    line-height: 134px;
    font-family: 'Bluu-Next-Bold';
    font-weight: 700;
    white-space: nowrap;
    font-size: 2.3em;
}

@media (max-width: 576px) {
    :root {
        --font-size-copt-1: 1.5em;
        --font-size-copt-2: 2.5em;
        /*font size of close-of-play-text */
    }

    .close-of-play-text {
        display: flex;
        align-items: flex-end;
        font-size: var(--font-size-copt-2);
    }

    .animation-wrapper.start::before,
    .animation-wrapper.start::after {
        display: none !important;
        width: 0px;
    }
}

@media (min-width: 576px) {
    :root {
        --font-size-copt-1: 4em;
        --font-size-copt-2: 5em;
        /*font size of close-of-play-text */
    }

    .close-of-play-text {
        font-size: var(--font-size-copt-2);
    }
}

@media (min-width: 992px) {
    :root {
        --font-size-copt-1: 5em;
        --font-size-copt-2: 7em;
    }

    /*font size of close-of-play-text */
    .close-of-play-text {
        font-size: var(--font-size-copt-2);
    }
}

/* The animation code */
@keyframes circle-fade-in {
    0% {
        opacity: 0;
        width: 100%;
        font-size: 0px;
    }

    30% {
        opacity: 1;
        font-size: var(--font-size-copt-1);
    }

    50% {
        opacity: 1;
        width: 100%;
        font-size: var(--font-size-copt-2);
    }

    100% {
        opacity: 1;
        width: 100%;
        font-size: var(--font-size-copt-2);
    }
}

@keyframes circle-part-fade-out-left {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 1;
        left: calc(0px - 120px);
    }

    100% {
        opacity: 0;
        left: calc(0px - 300px);
    }
}

@keyframes circle-part-fade-out-right {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 1;
        right: calc(0px - 120px);
    }

    100% {
        opacity: 0;
        right: calc(0px - 300px);
    }
}

/* The element to apply the animation to */
.fade-in-from-circle {
    opacity: 0;
    margin: auto;
    line-height: 100px;
    height: 120px;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    min-width: 110px;
}

.animation-wrapper.start .fade-in-from-circle {
    opacity: 1;
    margin: auto;
    line-height: 100px;
    height: 150px;
    width: auto;
    animation-name: circle-fade-in;
    animation-duration: 4s;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.animation-wrapper.start::after {
    opacity: 0;
    content: '';
    display: inline-block;
    cursor: pointer;
    width: 110px;
    height: 133px;
    background-repeat: no-repeat;
    background-image: url(../assets/close-of-play-brackets/close-of-play-2.png);
    background-size: cover;
    position: absolute;
    animation-name: circle-part-fade-out-right;
    animation-duration: 4s;
    top: calc(50% - 100px);
    right: calc(50% - 120px);
}

.animation-wrapper.start::before {
    opacity: 0;
    content: '';
    display: inline-block;
    cursor: pointer;
    width: 110px;
    height: 133px;
    background-repeat: no-repeat;
    background-image: url(../assets/close-of-play-brackets/close-of-play-1.png);
    background-size: cover;
    position: absolute;

    animation-name: circle-part-fade-out-left;
    animation-duration: 4s;
    animation-delay: 0.33ms;
    top: calc(50% - 78px);
    left: calc(50% - 52px);
}

.animation-wrapper {
    margin: auto;
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Fading animation */

.fade {
    animation-name: fade;
    -webkit-animation-name: fade;
    animation-duration: 4s;
    -webkit-animation-duration: 4s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
}

@-webkit-keyframes fade {
    from {
        opacity: 0.3;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0.3;
    }

    to {
        opacity: 1;
    }
}

.slideInLeft {
    -webkit-animation-name: slide-in-left;
    animation-name: slide-in-left;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    visibility: visible !important;
}

@-webkit-keyframes slide-in-left {
    from {
        margin-left: -40%;
    }

    to {
        margin-left: 0;
    }
}

@keyframes slide-in-left {
    from {
        margin-left: -40%;
    }

    to {
        margin-left: 0;
    }
}

.slideInRight {
    -webkit-animation-name: slide-in-right;
    animation-name: slide-in-right;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    visibility: visible !important;
}

@-webkit-keyframes slide-in-right {
    0% {
        margin-right: -40%;
    }

    100% {
        margin-right: 0;
    }
}

@keyframes slide-in-right {
    0% {
        margin-right: -40%;
    }

    100% {
        margin-right: 0;
    }
}
